const footerLinks = [
	{ label: "Reale Group", link: "http://www.realegroup.eu" },
	{ label: "Reclami", link: "https://www.realemutua.it/reclami" },
	{
		label: "Attestato di Rischio",
		link: "https://www.realemutua.it/strumenti/attestato-di-rischio",
	},
	{ label: "Contattaci", link: "https://www.realemutua.it/contatti" },
	{
		label: "Cookie & Privacy",
		link: "https://www.realemutua.it/privacy",
	},
	{
		label: "Lavora con noi",
		link: "http://www.realegroup.eu/IT/careers",
	},
];

const drawerWidth = 270;
const statsLength = [0, 1, 2, 3, 4, 5];

const errorMessage =
	"Questo è un messaggio di errore RPA (visibile solo se la richiesta è stata elaborata dall'RPA e sono stati evidenziati degli errori)";

const tableIntl = {
	pagination: {
		labelDisplayedRows: "{from}-{to} di {count}",
		labelRowsSelect: "richieste",
		firstAriaLabel: "Prima pagina",
		firstTooltip: "Prima pagina",
		previousAriaLabel: "Pagina precedente",
		previousTooltip: "Pagina precedente",
		nextAriaLabel: "Prossima pagina",
		nextTooltip: "Prossima pagina",
		lastAriaLabel: "Ultima pagina",
		lastTooltip: "Ultima pagina",
	},
	toolbar: {
		nRowsSelected: "{0} riga/righe selezionate",
		searchTooltip: "Ricerca",
		searchPlaceholder: "Ricerca",
	},
	header: {
		actions: "",
	},
	body: {
		emptyDataSourceMessage: "Nessun elemento",
		filterRow: {
			filterTooltip: "Filtra",
		},
	},
};

const momentIntl = {
	months: "gennaio_febbraio_marzo_aprile_maggio_giugno_luglio_agosto_settembre_ottobre_novembre_dicembre".split(
		"_"
	),
	monthsShort: "gen_feb_mar_apr_mag_giu_lug_ago_set_ott_nov_dic".split("_"),
	monthsParseExact: true,
};

export {
	footerLinks,
	tableIntl,
	drawerWidth,
	statsLength,
	momentIntl,
	errorMessage,
};
