import React from "react";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { statsLength } from "config/constants";
import { Stat } from "shared/Stat";

export function Stats({ stats, type }) {
	return (
		<>
			<details style={{ marginTop: "-18px" }}>
				<summary style={{ outline: "none", margin: "0 0 0.5rem 1rem" }}>
					Apri/Chiudi statistiche
				</summary>
				<Grid container spacing={(4, 2)}>
					{stats
						? stats.map((stat) => {
								if (
									stat.status_name !==
									"azure_wait_ack_in_folder"
								) {
									return (
										<Stat
											key={stat.status_name}
											label={stat.status_label}
											value={stat.status_count}
											onClick={() =>
												window.open(
													`/dashboard/${type}?status=${stat.status_name}`,
													"_blank"
												)
											}
										/>
									);
								}
						  })
						: statsLength.map((n) => <Stat key={n} isLoading />)}
				</Grid>
			</details>
		</>
	);
}

Stats.propTypes = {
	stats: PropTypes.arrayOf(
		PropTypes.shape({
			status_name: PropTypes.string.isRequired,
			status_label: PropTypes.string.isRequired,
			status_count: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number,
			]).isRequired,
		})
	),
	type: PropTypes.string,
};
