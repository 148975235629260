import React, { useEffect, useState } from "react";
import { Stats, Table } from "components/Dashboard";
import { axiosInstance } from "services/api";

export function Dashboard({ isReadOnly, type }) {
	const [stats, setStats] = useState(null);
	const [selected, setSelected] = useState([]);

	useEffect(() => {
		setStats(null);
		axiosInstance
			.get(`/${type}/services/dashboard/${type}/stats`)
			.then(({ data }) => {
				setSelected(data.map((stat) => stat.status_name));
				setStats(data);
			});
	}, [type]);

	return (
		<>
			<Stats stats={stats} type={type} />
			<Table statuses={selected} isReadOnly={isReadOnly} type={type} />
		</>
	);
}
