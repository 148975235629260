import React from "react";
import {
	Box,
	Button,
	Container,
	Grid,
	makeStyles,
	Paper,
	Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import azure from "assets/images/azure.png";
import logo from "assets/images/logo.png";
import { Footer } from "shared/Footer";

const useStyles = makeStyles((theme) => ({
	header: {
		backgroundColor: "white",
		minHeight: "100px",
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-start",
	},
	logo: {
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
	},
	content: {
		backgroundImage: "url('../assets/images/landing-page-background.png')",
		backgroundPosition: "top center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "100%",
		[theme.breakpoints.down("sm")]: {
			backgroundSize: "auto 400px",
		},
	},
}));

export function Login({ onLogin }) {
	const classes = useStyles();

	return (
		<Box>
			<Box id="header" className={classes.header}>
				<Container id="container" fixed={true} maxWidth="md">
					<Grid item xs={12} className={classes.logo}>
						<img
							src={logo}
							alt="Reale Mutua logo"
							width="170"
							height="42"
						/>
					</Grid>
				</Container>
			</Box>
			<Box id="content" className={classes.content}>
				<Container id="form_container" fixed>
					<Grid container direction="row" justify="center">
						<Grid item xs={12} sm={10} md={7}>
							<Box my={4} pb={8} position="relative">
								<Paper
									square
									elevation={5}
									style={{
										minHeight: "50vh",
										padding: "2rem 2rem 4rem",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										flexDirection: "column",
									}}
								>
									<Box my={4}>
										<Typography
											variant="h5"
											align="center"
											className={classes.title}
										>
											Accedi con Azure
										</Typography>
									</Box>
									<Button
										variant="contained"
										color="primary"
										size="large"
										onClick={onLogin}
									>
										<img
											src={azure}
											style={{
												padding: "2",
												height: "30px",
											}}
										/>
									</Button>
								</Paper>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Footer />
		</Box>
	);
}

Login.propTypes = {
	onLogin: PropTypes.func.isRequired,
};
