import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { axiosInstance } from "services/api";
import { getErrorMessage } from "utils";
import { SettingsTable } from "./SettingsTable";

export function SendersSettings({ roles }) {
	const { enqueueSnackbar } = useSnackbar();

	const [emails, setEmails] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	const handleEmailDelete = (email) => {
		setIsLoading(true);
		axiosInstance
			.delete(`/rid/services/config/delete?email=${email}`)
			.then(() => {
				setIsLoading(false);
				enqueueSnackbar(
					`Indirizzo email ${email} eliminato correttamente`,
					{
						variant: "success",
					}
				);
			})
			.catch((error) => {
				setIsLoading(false);
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	};

	const handleEmailsSettingsSave = (emailsSettings) => {
		setIsLoading(true);
		if (emailsSettings) {
			const updatedEmail = emailsSettings.map((email) => {
				return {
					email: email.email,
					type: email.type,
					group: email.group,
				};
			});
			const emailsToSave = updatedEmail.map((email) => {
				const gr =
					email.group === "Definito da polizza" ||
					email.group === undefined
						? null
						: email.group;
				return {
					email: email.email,
					type: {
						default: email.type,
					},
					group: {
						default: gr,
					},
				};
			});

			axiosInstance
				.put(`/rid/services/config/updateall`, emailsToSave)
				.then(() => {
					setIsLoading(false);
					setEmails(updatedEmail);
					enqueueSnackbar("Impostazioni aggiornate correttamente", {
						variant: "success",
					});
				})
				.catch((error) => {
					setIsLoading(false);
					enqueueSnackbar(getErrorMessage(error), {
						variant: "error",
					});
				});
		}
	};

	function getEmails() {
		setIsLoading(true);
		axiosInstance
			.get("/rid/services/config/show")
			.then(({ data }) => {
				const newData = data.map((email) => {
					return {
						email: email.email,
						type: email.type.default,
						group: email.group.default,
					};
				});
				setEmails(newData);
			})
			.catch((error) => {
				setIsLoading(false);
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	}

	useEffect(() => {
		getEmails();
	}, []);

	useEffect(() => {
		if (emails) {
			setIsLoading(false);
		}
	}, [emails]);

	return (
		<Grid id="grid-table">
			{emails && emails.length > 0 && (
				<>
					<SettingsTable
						emailsSettings={emails}
						onEmailsSettingsSave={handleEmailsSettingsSave}
						onEmailDelete={handleEmailDelete}
						isLoading={isLoading}
						roles={roles}
					/>
				</>
			)}
		</Grid>
	);
}
