import React from "react";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import { footerLinks } from "config/constants";

const useStyles = makeStyles((theme) => ({
	footerLinks: {
		fontFamily: "'Titillium Web', sans-serif",
		px: 15,
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			px: 2,
		},
	},
}));

export function Footer() {
	const classes = useStyles();

	return (
		<Container id="footer" align="center" maxWidth="lg">
			<Box
				pb={6}
				className={classes.footerLinks}
				display="flex"
				justifyContent="space-around"
			>
				{footerLinks.map((link, index) => (
					<Box key={index}>
						<Typography variant="body2">
							<a
								href={link.link}
								target="_blank"
								rel="noopener noreferrer"
							>
								{link.label}
							</a>
						</Typography>
					</Box>
				))}
			</Box>

			<Box pb={6}>
				<a
					href="https://www.realemutua.it/scopri-reale-mutua/chi-siamo"
					target="_blank"
					rel="noopener noreferrer"
				>
					Reale Mutua Assicurazioni - Partita IVA 11998320011 / C.F.
					00875360018
				</a>
			</Box>
		</Container>
	);
}
