import React from "react";
import ReactDOM from "react-dom";
import "./assets/index.css";
import App from "App";
import * as serviceWorker from "./serviceWorker";

// REMOVE CONSOLE LOG IN PRODUCTION
if (process.env.NODE_ENV === "production") {
	console.log = function () {};
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
