import React, { forwardRef } from "react";
import { Button, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

// eslint-disable-next-line react/display-name
export const FileUpload = forwardRef(
  (
    {
      onUpload,
      multiple = false,
      label = "Carica file",
      buttonSize = "large",
      buttonStyle = {
        minWidth: 240,
      },
    },
    ref
  ) => {
    return (
      <>
        <input
          ref={ref}
          style={{
            display: "none",
          }}
          multiple={multiple}
          onChange={(event) =>
            onUpload(multiple ? event.target.files : event.target.files[0])
          }
          id="upload-file"
          type="file"
        />
        <label htmlFor="upload-file">
          <Button
            component="span"
            variant="contained"
            color="secondary"
            size={buttonSize}
            style={{
              ...buttonStyle,
              margin: "1rem 0",
              marginRight: "1rem",
            }}
          >
            {label}
          </Button>
        </label>
      </>
    );
  }
);

FileUpload.propTypes = {
  onUpload: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  label: PropTypes.string,
};
