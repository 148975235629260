import React, { useEffect, useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Card,
	CardContent,
	CardHeader,
	Chip,
	FormHelperText,
	Grid,
	Input,
	makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSnackbar } from "notistack";
import { axiosInstance } from "services/api";
import { getErrorMessage } from "utils";

const useStyles = makeStyles((theme) => ({
	details: {
		display: "flex",
		direction: "row",
		justifyContent: "space-between",
		width: "100%",
	},
	accordion: {
		marginTop: "0.8rem",
	},
	formControl: {
		minWidth: 240,
		margin: theme.spacing(1),
	},
	actions: {
		display: "flex",
		direction: "row",
		justifyContent: "space-between",
		marginBottom: "0px",
	},
	action: {
		marginLeft: "1rem",
		boxShadow: "0 0 0 #000",
		backgroundColor: theme.palette.secondary.main,
	},
	wordsCard: {
		paddingBottom: "36px",
		height: "100%",
	},
	card: {
		height: "100%",
	},
	wordsList: { "flex-flow": "wrap" },
}));

export function KeywordsSettings({ roles }) {
	const classes = useStyles();
	const [keywords, setKeywords] = useState([]);

	function getKeywords() {
		axiosInstance.get("/rid/services/keywords/show").then(({ data }) => {
			const labels = ["RID"];
			const newData = labels.map((label) => {
				const labelData = data.filter((k) => k.label === label);
				return { label, sub_labels: labelData };
			});
			setKeywords(newData);
		});
	}

	const { enqueueSnackbar } = useSnackbar();

	function handleUpdateKeywords(label, sub_label, values) {
		const keywords = values.join();
		axiosInstance
			.put(
				`/rid/services/keywords/update?label=${label}&sub_label=${sub_label}&keywords=${keywords}`
			)
			.then(({ data }) => {
				if (data.length > 0) {
					data.forEach((key) =>
						enqueueSnackbar(
							`La chiave ${key.word} è già presente sotto la voce ${key.lemma}`,
							{
								variant: "error",
							}
						)
					);
				} else {
					enqueueSnackbar(`Chiavi aggiornate correttamente`, {
						variant: "success",
					});
				}
				getKeywords();
			})
			.catch((error) => {
				enqueueSnackbar(getErrorMessage(error), {
					variant: "error",
				});
			});
	}

	useEffect(() => {
		getKeywords();
	}, []);

	function KeywordsCard({ name, words, label }) {
		const [keywordValue, setKeywordValue] = useState(null);
		const [chipValues, setChipValues] = useState([]);

		function handleDelete(value) {
			axiosInstance
				.delete(
					`/rid/services/keywords/delete?label=${label}&sub_label=${name}&keyword=${value}`
				)
				.then(() => {
					getKeywords();
					enqueueSnackbar(`Chiave ${value} eliminata correttamente`, {
						variant: "success",
					});
				})
				.catch((error) => {
					enqueueSnackbar(getErrorMessage(error), {
						variant: "error",
					});
				});
		}

		return (
			<Card
				className={classes.card}
				// className={classes.root}
			>
				<CardHeader
					title={
						name === "OGGETTO"
							? `Chiavi identificative ${label}`
							: `Verbi identificativi ${name.toLowerCase()}`
					}
				/>
				{/* {name}</CardHeader> */}
				<CardContent className={classes.wordsCard}>
					<Box
						style={{
							height: "100%",
							display: "flex",
							justifyContent: "space-between",
							flexDirection: "column",
							paddingBottom: "60px",
						}}
						// className={classes.card}
					>
						<Box
							display="flex"
							// flexDirection="column"
							className={classes.wordsList}
						>
							{words.map((word, ind) =>
								roles.isConfig ? (
									<Chip
										key={ind}
										label={word}
										onDelete={(e) => {
											handleDelete(word);
										}}
										variant="outlined"
									/>
								) : (
									<Chip
										key={ind}
										label={word}
										variant="outlined"
									/>
								)
							)}
						</Box>
						<Box id={`inputBox${name}`} className={classes.actions}>
							{chipValues &&
								chipValues.length > 0 &&
								chipValues.map((value, i) => (
									<Chip
										key={i}
										label={value}
										// onDelete={(e) => {
										// 	handleDelete(value);
										// }}
										variant="outlined"
										// onChange={(e) => {
										// 	setKeywordValue(e.target.value);
										// }}
									/>
								))}
							{roles.isConfig && (
								<Box display="flex" flexDirection="column">
									{" "}
									<Input
										id={`input${name}`}
										aria-describedby={`text${name}`}
										value={keywordValue}
										onChange={(e) => {
											setKeywordValue(e.target.value);
										}}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												handleUpdateKeywords(
													label,
													name,
													[
														...chipValues,
														keywordValue,
													]
												);
												setChipValues([
													...chipValues,
													keywordValue,
												]);

												setKeywordValue("");
											}
										}}
									/>
									<FormHelperText>
										Premere invio per aggiungere una nuova
										chiave
									</FormHelperText>
									<FormHelperText>
										{" "}
										Per aggiungere più di una chiave
										separarle con virgola
									</FormHelperText>
									{name !== "OGGETTO" && (
										<FormHelperText>
											{" "}
											N.B.: I verbi inseriti verrano
											riportati automaticamente alla forma
											base
										</FormHelperText>
									)}
								</Box>
							)}
						</Box>
					</Box>
				</CardContent>
			</Card>
		);
	}
	return (
		<Box className={classes.accordion}>
			{keywords &&
				keywords.length > 0 &&
				keywords.map((keyword, index) => (
					<Accordion
						key={keyword.label}
						defaultExpanded={index === 0}
						width="100%"
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{keyword.label}
						</AccordionSummary>

						<AccordionDetails className={classes.details}>
							<Box
								display="flex"
								flexDirection="row"
								width="100%"
							>
								<Grid container spacing={2}>
									{keyword.sub_labels.map((subLabel) => (
										<Grid
											item
											xs={4}
											className={classes.card}
											key={subLabel.sub_label}
										>
											<KeywordsCard
												name={subLabel.sub_label}
												words={subLabel.keywords}
												label={keyword.label}
												className={classes.card}
											/>
										</Grid>
									))}
								</Grid>
							</Box>
						</AccordionDetails>
					</Accordion>
				))}
		</Box>
	);
}
