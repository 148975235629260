import React, { useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { Field } from "shared/Field";
import { matchPattern } from "utils";

export function EntitiesForm({
	entities = [],
	flagEsecutore,
	onSubmit,
	clientType,
	onMoreButtonClick,
	overview,
	isReadOnly,
	type,
}) {
	const { register, trigger, handleSubmit, errors, getValues, reset } =
		useFormContext();

	useEffect(() => {
		let entitiesValue = {};

		// TODO: FARE IN MODO DI NON PERDERE I VALORI MODIFICATI NON GIURIDICI AL CHANGE DEL TYPE
		if (clientType && clientType === "giuridica") {
			entitiesValue = getValues();

			entities.forEach((entity) => {
				if (entity.giuridico && entity.value !== undefined) {
					entitiesValue[entity.name] = "";
				} else {
					entitiesValue[entity.name] = entity.value;
				}
			});
		} else {
			entities.forEach((entity) => {
				entitiesValue[entity.name] = entity.value;
			});
		}
		trigger();
		reset(entitiesValue);
	}, [clientType, entities]);

	return (
		<>
			<form id="form" onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={2}>
					{entities.map((entity, index) => {
						let formattedEntity = { ...entity };
						if (
							isReadOnly ||
							overview.status.value === "inactive"
						) {
							formattedEntity.readonly = true;
						}
						return (
							<Grid item xs={12} key={index} sm={6}>
								<Field
									entity={formattedEntity}
									error={errors[formattedEntity.name]}
									entityRef={register({
										pattern: matchPattern(
											formattedEntity.pattern
										),
										required: formattedEntity.mandatory,
									})}
								/>
							</Grid>
						);
					})}
				</Grid>
			</form>

			{onMoreButtonClick &&
				!type === "DICHIARAZIONE" &&
				(!flagEsecutore ? (
					<Button
						color="primary"
						startIcon={<Add />}
						onClick={onMoreButtonClick}
						style={{ marginTop: "1rem" }}
					>
						Carica entità esecutore
					</Button>
				) : (
					<Button
						color="primary"
						startIcon={<Remove />}
						onClick={onMoreButtonClick}
						style={{ marginTop: "1rem" }}
					>
						Nascondi entità esecutore
					</Button>
				))}
		</>
	);
}

EntitiesForm.propTypes = {
	entities: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			label: PropTypes.string,
			pattern: PropTypes.string,
			mandatory: PropTypes.bool,
			readonly: PropTypes.bool,
			value: PropTypes.any,
		})
	).isRequired,
	clientType: PropTypes.string,
	flagEsecutore: PropTypes.bool,
	onSubmit: PropTypes.func.isRequired,
	onMoreButtonClick: PropTypes.any,
};
