import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { drawerWidth } from "config/constants";
import { Bar } from "./Bar";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(4, 2),
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: 0,
	},
	contentShift: {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
		[theme.breakpoints.up("sm")]: {
			marginLeft: drawerWidth,
		},
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
		justifyContent: "flex-end",
	},
}));

export function Main({ open, handleDrawerOpen, handleDrawerClose, children }) {
	const classes = useStyles();

	return (
		<>
			<Bar
				open={open}
				handleDrawerOpen={handleDrawerOpen}
				handleDrawerClose={handleDrawerClose}
			/>
			<main
				className={clsx(classes.content, {
					[classes.contentShift]: open,
				})}
			>
				<div className={classes.drawerHeader} />
				<Grid container className={classes.root} spacing={4}>
					{children}
				</Grid>
			</main>
		</>
	);
}

Main.propTypes = {
	open: PropTypes.bool,
	handleDrawerOpen: PropTypes.func,
	handleDrawerClose: PropTypes.func,
	children: PropTypes.element.isRequired,
};
