import React from "react";
import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from "@material-ui/core";

export function ClientTypeRadio({ value, onChange, disabled }) {
	return (
		<FormControl component="fieldset" style={{ marginBottom: "1.5rem" }}>
			<FormLabel component="legend">Tipologia contraente</FormLabel>
			<RadioGroup value={value} onChange={(e, v) => onChange(v)} row>
				<FormControlLabel
					value="fisica"
					control={<Radio />}
					label="Persona fisica"
					disabled={disabled}
				/>
				<FormControlLabel
					value="giuridica"
					control={<Radio />}
					label="Persona giuridica"
					disabled={disabled}
				/>
			</RadioGroup>
		</FormControl>
	);
}
